
    import Component from 'vue-class-component'
    import Vue from 'vue'

    import ComponentMessagesList from '@/components/messages/ComponentMessagesList.vue'
    import { getModule } from 'vuex-module-decorators'
    import Interface from '@/store/modules/interface'
    import ComponentFoldersTree from '@/components/messages/ComponentFoldersTree.vue'
    import ComponentMessageContent from '@/components/messages/ComponentMessageContent.vue'
    import ComponentMessageAttachments from '@/components/messages/ComponentMessageAttachments.vue'
    import ComponentMessageThread from '@/components/messages/ComponentMessageThread.vue'
    import Messages from '@/store/modules/messages'
    import { ContentMode } from '@/api/enums'
    import ComponentBosNotes from '@/components/messages/ComponentBosNotes.vue'
    import { Message } from '@/models/data/message'
    import env from '@/env/env'

    @Component({
        components: {
            ComponentFoldersTree,
            ComponentMessageAttachments,
            ComponentMessageContent,
            ComponentMessageThread,
            ComponentMessagesList,
            ComponentBosNotes
        }
    })
    export default class PageMessagesList extends Vue {
        interfaceModule = getModule(Interface, this.$store)
        messagesModule = getModule(Messages, this.$store)

        randomFact = ''
        placeholderHeight = 300
        hasNotes = false

        updateHasNotes (hasNotes: boolean) {
            this.hasNotes = hasNotes
        }

        get isSidebarVisible (): boolean {
            return this.interfaceModule.isSidebarVisible
        }

        set isSidebarVisible (value: boolean) {
            if (value) {
                getModule(Interface, this.$store).showSidebar()
            } else {
                getModule(Interface, this.$store).hideSidebar()
            }
        }

        get watchingEntry (): Message | null {
            return this.messagesModule.watchingEntry
        }

        get isAnyMessageSelected (): boolean {
            return this.messagesModule.watchingEntry.id !== 0
        }

        get isThreadMessageSelected (): boolean {
            return this.messagesModule.threadWatchingEntry.id !== 0
        }

        get threadContentMode (): ContentMode {
            return ContentMode.VIEW
        }

        async loadRandomFact (): Promise<void> {
            const response = await fetch('https://api.api-ninjas.com/v1/facts?limit=1', {
                method: 'GET',
                headers: {
                    // eslint-disable-next-line
                    'X-Api-Key': env.ninjasFactsApiKey
                }
            })
            const fact = await response.json()
            this.randomFact = fact[0].fact
        }

        createMessage (): void {
            window.open('/messages/new', '_blank')
        }

        mounted (): void {
            this.loadRandomFact()

            const placeholder = document.querySelector('#placeholder')
            if (placeholder) {
                this.placeholderHeight = window.innerHeight - placeholder.clientHeight - 160
            }
        }
    }
