
    import Vue from 'vue'
    import { Component, Prop } from 'vue-property-decorator'
    import { getModule } from 'vuex-module-decorators'
    import Messages from '@/store/modules/messages'
    import { Attachment, Message } from '@/models/data/message'
    import { ContentMode } from '@/api/enums'

    @Component
    export default class ComponentMessageAttachments extends Vue {
        @Prop({ default: ContentMode.MAIN }) readonly contentMode?: ContentMode
        @Prop({ default: undefined }) readonly sourceMessage?: Message

        messagesModule = getModule(Messages, this.$store)

        overlay = false
        image = ''

        get message (): Message {
            if (this.sourceMessage) {
                return this.sourceMessage
            }

            if (this.contentMode === ContentMode.MAIN) {
                return this.messagesModule.watchingEntry
            }

            return this.messagesModule.threadWatchingEntry
        }

        get loading (): boolean {
            return this.messagesModule.watchingMessageLoading
        }

        get attachments (): Array<Attachment> {
            return this.message.attachments
        }

        get maxWidth (): number {
            return window.innerWidth * 0.9
        }

        get maxHeight (): number {
            return window.innerHeight * 0.9
        }

        show (attachment: Attachment): void {
            this.overlay = true
            this.image = attachment.content
        }
    }
