
    import Vue from 'vue'
    import { Component, Prop, Watch } from 'vue-property-decorator'
    import HttpClient from '@/lib/http-client'
    import Notifications from '@/lib/notifications'
    import { Message } from '@/models/data/message'
    import { DateTime } from 'luxon'

    @Component
    export default class ComponentBosNotes extends Vue {
        @Prop({ default: undefined }) readonly message?: Message

        loading = false
        // eslint-disable-next-line @typescript-eslint/naming-convention
        notes: Array<{ noteable_type: string, note: string, link: string, updated_at: string }> = []

        formatDate (date: string): string {
            return DateTime.fromSQL(date).toFormat('yyyy.MM.dd HH:mm')
        }

        async loadNotes (): Promise<void> {
            if (this.message === undefined) {
                return
            }

            this.loading = true
            const url = `/api/services/bos/get-notes/${this.message.id}`
            try {
                this.$emit('hasNotes', false)
                this.notes = await HttpClient.getJson(url)
                this.$emit('hasNotes', this.notes.length > 0)
            } catch (e) {
                Notifications.error('Wystąpił problem w trakcie ładowania notatek. Spróbuj odświeżyć stronę.')
            } finally {
                this.loading = false
            }
        }

        @Watch('message')
        async onMessageChange (): Promise<void> {
            await this.loadNotes()
        }

        async mounted (): Promise<void>{
            await this.loadNotes()
        }
    }
