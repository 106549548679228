
    import Vue from 'vue'
    import { Component, Watch } from 'vue-property-decorator'
    import { getModule } from 'vuex-module-decorators'
    import Messages from '@/store/modules/messages'
    import { Message } from '@/models/data/message'
    import { DataOptions, DataTableHeader } from 'vuetify'
    import ComponentMessageContent from '@/components/messages/ComponentMessageContent.vue'
    import ComponentBosSearch from '@/components/messages/ComponentBosSearch.vue'
    import { DateTime } from 'luxon'
    import { isEqual } from 'lodash'
    import Notifications from '@/lib/notifications'
    import { ContentMode } from '@/api/enums'

    @Component({
        components: { ComponentMessageContent, ComponentBosSearch }
    })
    export default class ComponentMessageThread extends Vue {
        messagesModule = getModule(Messages, this.$store)

        isExpansionVisible = true
        matchingDialog = false

        expanded: Array<Message> = []

        options: DataOptions = {
            groupBy: [],
            groupDesc: [],
            itemsPerPage: -1,
            multiSort: false,
            mustSort: false,
            page: 1,
            sortBy: ['data.date'],
            sortDesc: [true]
        }

        headers: Array<DataTableHeader> = [
            {
                text: '',
                value: 'data.has_attachments',
                width: 20,
                sortable: false
            },
            {
                text: 'Data',
                value: 'data.date',
                width: 100
            },
            {
                text: 'Temat',
                value: 'data.subject',
                width: 200
            },
            {
                text: 'Od',
                value: 'data.from',
                width: 100
            },
            {
                text: 'Do',
                value: 'data.to',
                width: 200
            },
            {
                text: '',
                value: 'data-table-expand'
            },
        ]

        formatDate (date: string): string {
            return DateTime.fromISO(date).toFormat('yyyy.MM.dd HH:mm')
        }

        // region getters&setters
        get loading (): boolean {
            return this.messagesModule.watchingMessageLoading
        }

        get messages (): Array<Message> {
            return this.messagesModule.threadMessages
        }

        get parentMessage (): Message {
            return this.messagesModule.watchingEntry
        }

        get hasContractor (): boolean {
            return this.parentMessage.details.contractor?.nickname !== null
        }

        get hasOrder (): boolean {
            return this.parentMessage.details.order?.order_id !== undefined
        }

        get isThreadMessageSelected (): boolean {
            return this.messagesModule.threadWatchingEntry.id !== 0
        }

        get threadContentMode (): ContentMode {
            return ContentMode.VIEW
        }

        get threadsLoading (): boolean {
            return this.messagesModule.threadsLoading
        }
        // endregion

        selectedRow (message: Message): string {
            return isEqual(this.messagesModule.threadWatchingEntry, message) ? 'grey lighten-2' : ''
        }

        copyNickname (): void {
            if (typeof this.parentMessage.details.contractor.nickname === 'string') {
                navigator.clipboard.writeText(this.parentMessage.details.contractor.nickname)
                Notifications.info(`Skopiowano login klienta <b>${this.parentMessage.details.contractor.nickname}</b> do schowka.`)
            }
        }

        copyOrder (): void {
            if (typeof this.parentMessage.details.order?.order_id === 'string') {
                navigator.clipboard.writeText(this.parentMessage.details.order.order_id)
                Notifications.info(`Skopiowano numer zamówienia <b>${this.parentMessage.details.order.order_id}</b> do schowka.`)
            }
        }

        async showMessage (message: Message): Promise<void> {
            this.expanded = [message]
            await this.messagesModule.loadThreadWatchingMessageDetails(message)
            this.isExpansionVisible = false
        }

        @Watch('parentMessage')
        onParentMessageChange (): void {
            this.isExpansionVisible = true
            this.messagesModule.setThreadWatchingEntry()
            this.messagesModule.setThreadMessages([])
        }
    }
