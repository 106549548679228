
    import Vue from 'vue'
    import { Component } from 'vue-property-decorator'
    import { DataTableHeader } from 'vuetify'
    import HttpClient from '@/lib/http-client'
    import { IBosOrder } from '@/models/data/bos'
    import { BosOrdersResponse } from '@/models/responses/bos-orders-response'
    import ComponentPrice from '@/components/common/ComponentPrice.vue'
    import { getModule } from 'vuex-module-decorators'
    import Messages from '@/store/modules/messages'
    import { Customer } from '@/models/data/customer'
    import { Order } from '@/models/data/order'

    @Component({
        components: { ComponentPrice }
    })
    export default class ComponentBosSearch extends Vue {
        messagesModule = getModule(Messages, this.$store)

        saving = false
        orders: Array<IBosOrder> = []
        selected: Array<IBosOrder> = []
        loading = false

        menu1 = false
        menu2 = false
        menu3 = false
        menu4 = false
        menu5 = false
        menu6 = false
        menu7 = false
        menu8 = false
        criteria = {
            page: 1,
            paginate: 25,
            buyer_user_nickname: '',
            buyer_user_name: '',
            paid_status: '',
            shipped_status: '',
            exported_status: '',
            transaction_id: '',
            extended_order_id: '',
            custom_number: '',
            item_id: '',
            no_tracking_number: '',
            bkod: '',
            exported_at_1: '',
            exported_at_2: '',
            shipped_at_1: '',
            shipped_at_2: '',
            ordered_at_1: '',
            ordered_at_2: '',
            paid_at_1: '',
            paid_at_2: '',
            payment_method: '',
            instructing_id: '',
            comment_type: '',
            postorder_is_return: '',
            postorder_is_case: '',
            postorder_is_inquiry: '',
            postorder_is_cancellation: '',
            shipping_service: '',
            item_title: '',
            item_sku: '',
            quantity: '',
            main_order_id: '',
            store_list_uid: '',
            sort: '',
            direction: 'desc',
        }

        headers: Array<DataTableHeader> = [
            {
                text: 'Konto',
                value: 'account.name',
            },
            {
                text: 'D. zam.',
                value: 'ordered_at',
            },
            {
                text: 'Login',
                value: 'buyer_user_nickname',
            },
            {
                text: 'Kupujący',
                value: 'buyer_user_name',
            },
            {
                text: 'R. zapł.',
                value: 'payment_method',
            },
            {
                text: 'D. zapł.',
                value: 'paid_at',
            },
            {
                text: 'D. wysyłki.',
                value: 'shipped_at',
            },
            {
                text: 'Dest.',
                value: 'address_country_name',
            },
            {
                text: 'K. trans.',
                value: 'shipping_cost_value',
            },
            {
                text: 'Suma',
                value: 'total_value',
            },
            {
                text: 'Notatka (do zamówienia)',
                value: 'note',
            },
        ]

        // It's only temporary solution - waiting for BOS update to make one extendable model for these data.
        // Here we have to do this stuff to be able to assign orders from Template Configuration window.
        // todo(@szymekjanaczek)
        getCustomerFromOrder (order: IBosOrder): Customer {
            return new Customer({
                id: 0,
                nickname: order.buyer_user_nickname,
                name: order.buyer_user_name
            })
        }

        // It's only temporary solution - waiting for BOS update to make one extendable model for these data.
        // Here we have to do this stuff to be able to assign orders from Template Configuration window.
        // todo(@szymekjanaczek)
        formatOrder (order: IBosOrder): Order {
            return new Order({
                order_number: order.main_order_id,
                ordered_at_date: '',
                ordered_at_time: '',
                paid_at_date: '',
                paid_at_time: '',
                items: [],
                customer: new Customer()
            })
        }

        async assignCustomer (): Promise<void> {
            this.saving = true
            await this.messagesModule.assignContractor(this.getCustomerFromOrder(this.selected[0]))
            this.saving = false
        }

        async assignOrder (): Promise<void> {
            this.saving = true
            await this.messagesModule.assignOrder(this.formatOrder(this.selected[0]))
            this.saving = false
        }

        async assignBoth (): Promise<void> {
            this.saving = true
            await this.messagesModule.assignOrder(this.formatOrder(this.selected[0]))
            await this.messagesModule.assignContractor(this.getCustomerFromOrder(this.selected[0]))
            this.saving = false
        }

        async loadOrders (): Promise<void> {
            this.loading = true
            const url = '/api/services/bos/orders'
            const urlSearchParams = new URLSearchParams()

            for (const [key, value] of Object.entries(this.criteria)) {
                urlSearchParams.append(key, value.toString())
            }

            const response = await HttpClient.getJson<BosOrdersResponse>(url + '?' + urlSearchParams.toString())
            this.orders = response.list.data
            this.loading = false
        }
    }
