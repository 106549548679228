
    import Vue from 'vue'
    import { Component, Prop, Watch } from 'vue-property-decorator'
    import { getModule } from 'vuex-module-decorators'
    import Messages from '@/store/modules/messages'
    import { Message } from '@/models/data/message'
    import { messages as Api, services as servicesApi } from '@/api'
    import { ContentMode } from '@/api/enums'
    import { DateTime } from 'luxon'
    import Auth from '@/store/modules/auth'
    import env from '@/env/env'
    import HttpClient from '@/lib/http-client'
    import { ITranslation } from '@/models/data/translate'

    @Component
    export default class ComponentMessageContent extends Vue {
        @Prop({ default: false }) readonly fullHeight?: boolean
        @Prop({ default: ContentMode.MAIN }) readonly contentMode?: ContentMode
        @Prop({ default: undefined }) readonly sourceMessage?: Message

        authModule = getModule(Auth, this.$store)
        messagesModule = getModule(Messages, this.$store)
        showAlert = false

        url = ''

        translating = true
        translation = ''

        get loading (): boolean {
            if (this.isParentMessage) {
                return this.messagesModule.watchingMessageLoading
            } else {
                return this.messagesModule.threadWatchingMessageLoading
            }
        }

        get message (): Message {
            if (this.sourceMessage) {
                return this.sourceMessage
            }

            if (this.contentMode === ContentMode.MAIN) {
                return this.messagesModule.watchingEntry
            }

            return this.messagesModule.threadWatchingEntry
        }

        get isParentMessage (): boolean {
            return this.contentMode === ContentMode.MAIN
        }

        async getTranslation (): Promise<void> {
            this.translating = true
            this.translation = ''
            if (!this.message?.data?.text_body || this.message?.data?.text_body?.length === 0) {
                return
            }

            const urlSearchParams = new URLSearchParams({
                text: this.message.data.text_body,
                source_lang: 'de',
                target_lang: 'pl'
            })
            const query = '?' + urlSearchParams.toString()

            try {
                const response = await HttpClient.getJson<ITranslation>(servicesApi.deepl.translate + query)
                this.translation = response.text
            } finally {
                this.translating = false
            }
        }

        // @Debounce(1000, { leading: false })
        async loadBodyWithoutMedia (): Promise<void> {
            // console.log('start loading body without media', (new Date).toString())
            this.startLoading()

            const token = this.authModule.authenticatedUser?.access_token
            this.url = `${env.apiBasePath}${Api.getBody.withoutMedia(this.message)}?access_token=${token}`
            const iframe: any = this.$refs.messageContent

            if (iframe !== undefined) {
                iframe.onload = () => {
                    this.stopLoading()
                    // console.log('stop loading body without media', (new Date).toString())
                }
            }
        }

        async loadBodyWithMedia (): Promise<void> {

            // try {
            //     this.startLoading()
            //
            //     const response = await HttpClient.get(Api.getBody.withMedia(this.message))
            //
            //     this.writeMessageBody(await response.text())
            //     this.showAlert = false
            // } catch (e) {
            //     console.error(e)
            //     Notifications.error('Wystąpił problem w trakcie ładowania wiadomości. Spróbuj odświeżyć stronę.')
            // } finally {
            //     this.stopLoading()
            // }

            this.startLoading()

            const token = this.authModule.authenticatedUser?.access_token
            this.url = `${env.apiBasePath}${Api.getBody.withMedia(this.message)}?access_token=${token}`
            const iframe: any = this.$refs.messageContent

            if (iframe !== undefined) {
                iframe.onload = () => {
                    this.stopLoading()
                }
            }
        }

        // writeMessageBody (body: string): void {
        //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
        //     const iframe: any = this.$refs.messageContent
        //     const iframeContent = iframe.contentDocument
        //
        //     iframeContent.body.innerHTML = ''
        //
        //     // Open all links as new tabs.
        //     const parser = new DOMParser()
        //     const doc = parser.parseFromString(body, 'text/html')
        //     doc.querySelectorAll('a').forEach((element: HTMLElement) => {
        //         element.setAttribute('target', '_blank')
        //     })
        //     iframeContent.body.innerHTML = doc.body.innerHTML
        // }

        shortenText (text: string, maxLength: number): string {
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...'
            }

            return text
        }

        formatDate (date: string): string {
            return DateTime.fromSQL(date).toFormat('yyyy.MM.dd HH:mm')
        }

        startLoading (): void {
            console.log(`start loading, is parent: ${this.isParentMessage}`)
            if (this.isParentMessage) {
                this.messagesModule.startWatchingMessageLoading()
            } else {
                this.messagesModule.startThreadWatchingMessageLoading()
            }
        }

        stopLoading (): void {
            console.log(`stop loading, is parent: ${this.isParentMessage}`)
            if (this.isParentMessage) {
                this.messagesModule.stopWatchingMessageLoading()
            } else {
                this.messagesModule.stopThreadWatchingMessageLoading()
            }
        }

        reply (): void {
            window.open(`/messages/${this.message.id}/reply`, '_blank')
        }

        forward (): void {
            window.open(`/messages/${this.message.id}/forward`, '_blank')
        }

        @Watch('message')
        onMessageChanged (): void {
            this.translation = ''
            this.startLoading()
            this.loadBodyWithoutMedia()
            this.getTranslation()
            this.showAlert = true
            this.stopLoading()
        }

        mounted (): void {
            this.loadBodyWithoutMedia()
        }
    }
