
    import Vue from 'vue'
    import { Component, Prop } from 'vue-property-decorator'

    @Component
    export default class ComponentPrice extends Vue {
        @Prop({ default: 0 }) readonly price?: number
        @Prop({ default: 'EUR' }) readonly currency?: string
        @Prop({ default: 0 }) readonly emptyPrice?: number
        @Prop({ default: 'EUR' }) readonly emptyCurrency?: string
    }
