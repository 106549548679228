
    import Vue from 'vue'
    import { Component, Watch } from 'vue-property-decorator'
    import { getModule } from 'vuex-module-decorators'
    import Messages from '@/store/modules/messages'
    import { Message } from '@/models/data/message'
    import { DataTableHeader, DataTableItemProps } from 'vuetify'
    import { DateTime } from 'luxon'
    import { PaginationMeta } from '@/models/data/pagination/pagination-meta'
    import Mode from '@/store/mode'
    import { isEqual, throttle } from 'lodash'
    import { Tag } from '@/models/data/tag'
    import Tags from '@/store/modules/tags'
    import { MessageFlagName } from '@/api/enums'
    import Notifications from '@/lib/notifications'

    @Component
    export default class ComponentMessagesList extends Vue {
        messagesModule = getModule(Messages, this.$store)
        tagsModule = getModule(Tags, this.$store)

        showIntersect = false
        dragging = false
        saving = {
            assignTags: false,
            deleteSelected: false,
            markAsRead: false,
            markAsUnread: false,
            note: false,
            unassignTags: false
        }

        contextMenu = {
            isVisible: false,
            message: new Message(),
            x: 0,
            y: 0,
            operations: [
                {
                    title: 'Oznacz jako przeczytane',
                    operation: this.markAsRead
                },
                {
                    title: 'Oznacz jako nieprzeczytane',
                    operation: this.markAsUnread
                },
            ]
        }

        headers: Array<DataTableHeader> = [
            {
                text: '',
                value: 'options',
                width: 20,
                sortable: false
            },
            {
                text: '',
                value: 'data.has_attachments',
                width: 20,
                sortable: false
            },
            {
                text: 'Data',
                value: 'data.date',
                width: '15%'
            },
            {
                text: 'Kontrahent',
                value: 'details.contractor.nickname',
                width: '10%'
            },
            {
                text: 'Temat',
                value: 'data.subject',
                width: '25%'
            },
            {
                text: 'Od',
                value: 'data.from',
                width: '15%'
            },
            {
                text: 'Do',
                value: 'data.to',
                width: 150
            },
            {
                text: 'Folder',
                value: 'identifiers.path',
                sortable: false
            },
            {
                text: 'Notatka',
                value: 'details.note'
            }
        ]

        tagsToAssign: Array<Tag> = []
        tagsToUnassign: Array<Tag> = []

        // region getters and setters
        get messages (): Array<Message> {
            return this.messagesModule.entries.data
        }

        get tags (): Array<Tag> {
            return this.tagsModule.entries
        }

        get note (): string {
            return this.messagesModule.activeEntry.details.note.note
        }

        set note (value: string) {
            this.messagesModule.setNote(value)
        }

        get selectedMessages (): Array<Message> {
            return this.messagesModule.selectedMessages
        }

        set selectedMessages (value: Array<Message>) {
            this.messagesModule.setSelectedMessages(value)
        }

        get tableHeight (): number {
            return window.screen.height / 4
        }

        get sortBy (): string {
            return this.messagesModule.searchParams.sortBy ?? ''
        }

        set sortBy (value: string) {
            this.messagesModule.setSortBy(value)
        }

        get sortDesc (): boolean {
            return this.messagesModule.searchParams.sortDesc ?? false
        }

        set sortDesc (value: boolean) {
            this.messagesModule.setSortDesc(value)
        }

        get meta (): PaginationMeta {
            return this.messagesModule.entries.meta
        }

        get loading (): boolean {
            return this.messagesModule.loading
        }

        set loading (value: boolean) {
            if (value) {
                this.messagesModule.startMessagesLoading()
            } else {
                this.messagesModule.stopMessagesLoading()
            }
        }

        get usedDeepSearch (): boolean {
            return this.messagesModule.usedDeepSearch
        }

        set usedDeepSearch (value: boolean) {
            this.messagesModule.setUsedDeepSearch(value)
        }
        // endregion

        selectedRow (message: Message): string {
            return isEqual(this.messagesModule.watchingEntry, message) ? 'grey lighten-2' : ''
        }

        startDrag (event: DragEvent, item: Message): void {
            const dataTransfer = event.dataTransfer as DataTransfer
            dataTransfer.dropEffect = 'move'
            dataTransfer.effectAllowed = 'move'

            let itemsList: Array<number>
            if (this.selectedMessages.length > 0 && this.selectedMessages.includes(item)) {
                itemsList = this.selectedMessages.map((message: Message) => this.messages.indexOf(message))
            } else {
                itemsList = [this.messages.indexOf(item)]
            }

            dataTransfer.setData('items', itemsList.join(','))
            this.dragging = true
        }

        formatDate (date: string): string {
            return DateTime.fromISO(date).toFormat('yyyy.MM.dd HH:mm')
        }

        // region note management
        edit (message: Message): void {
            this.messagesModule.setSelectedEntry(message)
            this.messagesModule.setMode(Mode.EDIT)
        }

        async updateNote (): Promise<void> {
            this.saving.note = true
            Notifications.info('Zapisywanie notatki.')
            try {
                await this.messagesModule.updateNote()
                Notifications.success('Notatka zapisana')
            } catch (e) {
                Notifications.error('no to dupa...')
            } finally {
                this.saving.note = false
            }
        }

        shortenPath (path: string): string {
            const maxLength = 9
            if (path.length <= maxLength) {
                return path
            }

            return path.slice(0, maxLength) + '...'
        }

        close (): void {
            this.saving.note = false
        }
        // endregion

        showContextMenu (e: MouseEvent, row: DataTableItemProps): void {
            e.preventDefault()
            this.contextMenu.isVisible = false
            this.contextMenu.x = e.clientX
            this.contextMenu.y = e.clientY
            this.contextMenu.message = row.item
            this.$nextTick(() => {
                this.contextMenu.isVisible = true
            })
        }

        copyNickname (nickname: string): void {
            navigator.clipboard.writeText(nickname)
            Notifications.info(`Skopiowano login klienta <b>${nickname}</b> do schowka.`)
        }

        // showMovingDialog (): void {
        //     this.dialog.transferMessage.enabled = true
        //     this.dialog.transferMessage.mode = TransferMode.MOVE
        // }
        //
        // showCopyingDialog (): void {
        //     this.dialog.transferMessage.enabled = true
        //     this.dialog.transferMessage.mode = TransferMode.COPY
        // }
        //
        // async enableDeepSearch (): Promise<void> {
        //     this.messagesModule.setDeepSearch(true)
        //     this.messagesModule.setUsedDeepSearch(true)
        //     await this.search()
        //     this.messagesModule.setDeepSearch(false)
        // }
        //
        // async assignTags (): Promise<void> {
        //     this.saving.assignTags = true
        //     await this.messagesModule.assignTags(this.tagsToAssign)
        //     this.saving.assignTags = false
        // }
        //
        // async unassignTags (): Promise<void> {
        //     this.saving.unassignTags = true
        //     await this.messagesModule.unassignTags(this.tagsToUnassign)
        //     this.saving.unassignTags = false
        // }
        //
        // async markSelectedAsRead (): Promise<void> {
        //     this.saving.markAsRead = true
        //     this.messagesModule.selectedMessages.forEach((message: Message) => this.messagesModule.startMessageLoading(message))
        //     try {
        //         await this.messagesModule.massMark({ name: MessageFlagName.SEEN, value: true })
        //     } finally {
        //         this.messagesModule.selectedMessages.forEach((message: Message) => this.messagesModule.stopMessageLoading(message))
        //         this.saving.markAsRead = false
        //     }
        // }
        //
        // async markSelectedAsUnread (): Promise<void> {
        //     this.saving.markAsUnread = true
        //     this.messagesModule.selectedMessages.forEach((message: Message) => this.messagesModule.startMessageLoading(message))
        //     try {
        //         await this.messagesModule.massMark({ name: MessageFlagName.SEEN, value: false })
        //     } finally {
        //         this.messagesModule.selectedMessages.forEach((message: Message) => this.messagesModule.stopMessageLoading(message))
        //         this.saving.markAsRead = false
        //     }
        // }
        //
        // async deleteSelected (): Promise<void> {
        //     this.saving.deleteSelected = true
        //     await this.messagesModule.massDelete()
        //     this.saving.deleteSelected = false
        // }

        // region context menu actions
        async markAsRead (): Promise<void> {
            const message = this.contextMenu.message
            this.messagesModule.startMessageLoading(message)
            await this.messagesModule.markMessage({ message, flag: { name: MessageFlagName.SEEN, value: true } })
            this.messagesModule.stopMessageLoading(message)
        }

        async markAsUnread (): Promise<void> {
            const message = this.contextMenu.message
            this.messagesModule.startMessageLoading(message)
            await this.messagesModule.markMessage({ message, flag: { name: MessageFlagName.SEEN, value: false } })
            this.messagesModule.stopMessageLoading(message)

        }
        // endregion

        async showMessage (message: Message): Promise<void> {
            await Promise.all([
                this.messagesModule.loadWatchingMessageDetails(message),
                this.messagesModule.markMessage({ message, flag: { name: MessageFlagName.SEEN, value: true } })
            ])
        }

        async loadNextPage (entries: IntersectionObserverEntry): Promise<void> {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const isIntersecting = entries[0].isIntersecting
            if (isIntersecting && this.messages.length > 0 && !this.loading) {
                await throttle(async () => {
                    this.loading = true
                    this.messagesModule.setPage(this.messagesModule.entries.meta.nextPage)
                    try {
                        await this.messagesModule.loadNextPage()
                    } catch (e) {
                        Notifications.error(this.$t('messages.error.search') as string)
                    }
                    this.loading = false
                }, 500)()
            }
        }

        async search (): Promise<void> {
            this.loading = true
            try {
                await this.messagesModule.search()
            } catch (e) {
                Notifications.error(this.$t('messages.error.search') as string)
            }
            this.loading = false
        }

        async created (): Promise<void> {
            await this.search()
        }

        @Watch('loading')
        async loadingChanged (loading: boolean): Promise<void> {
            this.showIntersect = false
            await new Promise(r => setTimeout(r, 2000))
            if (!loading) {
                this.showIntersect = true
            }
        }
    }
